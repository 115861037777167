// APP CONFIG
export default {
  // App Company
  companyName: 'Popper', // Will update name in navigation menu (Branding)
  companyAppName: 'Bank Account',
  companyLink: 'https://www.google.co.th',
  companyLogoImage: '', // Will update logo in navigation menu (Branding)
  loginBanner: require('@/assets/images/banner/login.png'),

  /* DEFAULT DATA */
  // API_URL_DATABASE: 'https://erp-mediplex-dev.herokuapp.com', // dev
  // API_URL_DATABASE: 'http://192.168.1.103:1337', // dev from mint local
  API_URL_DATABASE: 'http://192.168.1.116:1338', // dev from server monospace
}
