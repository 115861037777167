export default [
  {
    title: 'Transfer',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'dashboard',
        route: 'transfer-dashboard',
      },
    ],
  },
]
