export default [
  {
    header: "รายการยอดเงินโอน",
    icon: "HomeIcon",
    children: [
      {
        title: 'Dashboard',
        route: 'transfer-dashboard',
        icon: 'BarChart2Icon',
      },
    ],
  },
];
