export default [
    {
      header: 'ตั้งค่าบัญชีธนาคาร',
      icon: 'FolderIcon',
      children: [
        {
          title: 'รายการบัญชี',
          route: 'account-lists',
          icon: 'UsersIcon',
        },
        {
          title: 'ตั้งค่าบัญชีผูกกับผู้ใช้งานปัจจุบัน',
          route: 'user-existings',
          icon: 'UsersIcon',
        },
      ],
    },
  ]
  