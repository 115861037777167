export const loadState = key => {
  try {
    if (typeof window !== 'undefined') {
      const serializedState = localStorage.getItem(key)
      if (serializedState === null) {
        return undefined
      }
      return JSON.parse(serializedState)
    }
  } catch (e) {
	  console.log('loadStorage error = ', e)
	  return undefined
  }
}

export const saveState = (key, state) => {
  try {
    if (typeof window !== 'undefined') {
      const serializedState = JSON.stringify(state)
      localStorage.setItem(key, serializedState)
    }
  } catch (e) {
	  console.log('saveState error = ', e)
  }
}

export const clearState = () => {
  try {
	  // window.localStorage.clear();
	  if (typeof window !== 'undefined') {
	  localStorage.clear()
	  }
	  // console.log("ล้างค่า");
  } catch (e) {
	  console.log('Clear error = ', e)
  }
}
