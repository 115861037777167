export default [
    {
      title: 'Management',
      icon: 'FolderIcon',
      tagVariant: 'light-warning',
      children: [
        {
          title: 'Account List',
          route: 'account-lists',
        },
        {
          title: 'User Existing',
          route: 'user-existings',
        },
      ],
    },
  ]
  